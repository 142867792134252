/*@jsxRuntime classic @jsx React.createElement @jsxFrag React.Fragment*/
import {useMDXComponents as _provideComponents} from "@mdx-js/react";
import React from "react";
function _createMdxContent(props) {
  const _components = Object.assign({
    ul: "ul",
    li: "li",
    p: "p",
    a: "a"
  }, _provideComponents(), props.components);
  return React.createElement(React.Fragment, null, React.createElement(_components.ul, null, "\n", React.createElement(_components.li, null, "\n", React.createElement(_components.p, null, React.createElement(_components.a, {
    href: "https://pixabay.com/photos/money-coin-investment-business-2724241/"
  }, "썸네일 원본")), "\n"), "\n", React.createElement(_components.li, null, "\n", React.createElement(_components.p, null, React.createElement(_components.a, {
    href: "https://openai.com/blog/introducing-the-gpt-store"
  }, "Introducing the GPT Store")), "\n"), "\n", React.createElement(_components.li, null, "\n", React.createElement(_components.p, null, React.createElement(_components.a, {
    href: "https://chat.openai.com/gpts"
  }, "GPT Store")), "\n"), "\n", React.createElement(_components.li, null, "\n", React.createElement(_components.p, null, React.createElement(_components.a, {
    href: "https://github.blog/2023-12-29-github-copilot-chat-now-generally-available-for-organizations-and-individuals/"
  }, "GitHub Copilot Chat GA")), "\n"), "\n", React.createElement(_components.li, null, "\n", React.createElement(_components.p, null, "뉴욕타임스가 OpenAI를 고소"), "\n", React.createElement(_components.ul, null, "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://openai.com/blog/openai-and-journalism"
  }, "OpenAI의 반박문")), "\n"), "\n"), "\n", React.createElement(_components.li, null, "\n", React.createElement(_components.p, null, React.createElement(_components.a, {
    href: "https://www.korea.kr/briefing/pressReleaseView.do?newsId=156608950&pWise=mSub&pWiseSub=C5#pressRelease"
  }, "벤처기업법 개정안 의결")), "\n", React.createElement(_components.ul, null, "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://www.zuzu.network/resource/guide/rsu/"
  }, "RSU?")), "\n"), "\n"), "\n", React.createElement(_components.li, null, "\n", React.createElement(_components.p, null, React.createElement(_components.a, {
    href: "https://radar.cloudflare.com/year-in-review/2023"
  }, "Cloudflare 트렌드 리포트")), "\n", React.createElement(_components.ul, null, "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://radar.cloudflare.com/year-in-review/2023/kr#internet-traffic-growth"
  }, "한국")), "\n"), "\n"), "\n"), "\n", React.createElement(_components.p, null, React.createElement(_components.a, {
    href: "https://www.childfund.or.kr/contents/greenView.do?bdId=20009977&bmId=10000148"
  }, "일본 지진"), "으로 어려움을 겪고 계신 분들께 위로를 전합니다."));
}
function MDXContent(props = {}) {
  const {wrapper: MDXLayout} = Object.assign({}, _provideComponents(), props.components);
  return MDXLayout ? React.createElement(MDXLayout, props, React.createElement(_createMdxContent, props)) : _createMdxContent(props);
}
export default MDXContent;
